@mixin striped-redactor($color: rgba(255, 255, 255, 0.2), $angle: 45deg) {
  background-image: -webkit-linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
}
@mixin animation-redactor($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

#redactor-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000000;
  height: 10px;
}
#redactor-progress span {
  @include striped-redactor;
  @include animation-redactor(progress-bar-stripes 2s linear infinite);

  display: block;
  width: 100%;
  height: 100%;
  background-color: #007dff;
  background-size: 40px 40px;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
