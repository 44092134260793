// Zindex
.redactor-box,
.redactor-box textarea {
  z-index: auto;
}

// Base
.redactor-box {
  position: relative;
  box-sizing: border-box;

  &.redactor-styles-on {
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 3px;
    box-shadow: none;
  }
  &.redactor-inline {
    position: static;
  }
}
.redactor-focus,
.redactor-focus:focus {
  &.redactor-styles-on {
    border-color: #82b7ec !important;
  }
}
.redactor-over:hover {
  &.redactor-styles-on {
    border-color: #ee698a !important;
  }
}
.redactor-source-view,
.redactor-source-view.redactor-styles-on {
  border-color: #000 !important;
}

// Editor
.redactor-in {
  position: relative;
  overflow: auto;
  white-space: normal;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.redactor-inline .redactor-in {
  overflow: hidden;
}
.redactor-in *,
.redactor-read-only * {
  outline: none !important;
}
.redactor-in {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  div[data-redactor-tag="br"],
  div[data-redactor-tag="tbr"] {
    &:empty {
      min-height: $base-line-ems;
    }
  }
  div[data-redactor-tag="br"],
  div[data-redactor-tag="tbr"] {
    margin-top: 0;
    margin-bottom: 0;
  }
  strong,
  b,
  em,
  i,
  span,
  sup,
  sub,
  u,
  ins {
    &:empty {
      display: inline-block;
      min-width: 1px;
      min-height: 1rem;
    }
  }
  table {
    empty-cells: show;
  }
  li {
    figure {
      width: auto;
      display: inline-block;
      margin: 0;
      vertical-align: top;
    }
  }
  figcaption,
  figure code,
  figure pre,
  table td,
  table th {
    &:focus {
      outline: none;
    }
  }
  figure[data-redactor-type="line"] {
    margin-top: 1em;
    padding: 6px 0;
    vertical-align: baseline;
    hr {
      margin: 0;
      height: 3px;
      border: none;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
.redactor-script-tag {
  display: none !important;
}

// Components
.redactor-component {
  position: relative;
}

.redactor-component[data-redactor-type="widget"]:before,
.redactor-component[data-redactor-type="video"]:before {
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
}
.redactor-component[data-redactor-type="image"],
.redactor-component[data-redactor-type="widget"] {
  clear: both;
}
.redactor-component[data-redactor-type="variable"] {
  white-space: nowrap;
  background: rgba(#007dff, 0.75);
  color: #fff;
  display: inline-block;
  padding: 3px 6px;
  line-height: 1;
  border-radius: 4px;
  cursor: pointer;
}
.redactor-component-active {
  outline: 5px solid rgba(#007dff, 0.5) !important;
}
.redactor-component-active[data-redactor-type="image"] {
  outline: none !important;
  & img {
    outline: 5px solid rgba(#007dff, 0.5) !important;
  }
}
.redactor-component-active[data-redactor-type="variable"] {
  outline: none !important;
  background: #ee698a;
}
.redactor-component-active[data-redactor-type="video"] {
  outline: none !important;
  & iframe {
    outline: 5px solid rgba(#007dff, 0.5) !important;
  }
}
.redactor-blur.redactor-styles-on {
  & .redactor-component-active {
    outline: 5px solid #ddd !important;
    &[data-redactor-type="image"] {
      outline: none !important;
      & img {
        outline: 5px solid #ddd !important;
      }
    }
    &[data-redactor-type="video"] {
      outline: none !important;
      & iframe {
        outline: 5px solid #ddd !important;
      }
    }
    &[data-redactor-type="variable"] {
      outline: none !important;
      background: #ddd;
    }
  }
}
.redactor-component-caret {
  position: absolute;
  left: -9999px;
}

// Utils
.redactor-textnodes-wrapper {
  display: inline-block;
}

// Image
#redactor-image-resizer {
  position: absolute;
  z-index: 1051;
  background-color: rgba(#007dff, 0.9);
  width: 13px;
  height: 13px;
  border: 1px solid #fff;
  cursor: move;
  cursor: nwse-resize;
}

// File
.redactor-file-item {
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.redactor-file-remover {
  margin-left: 2px;
  position: relative;
  right: -3px;
  display: inline-block;
  padding: 0 3px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

// Overlay
.redactor-overlay {
  position: fixed;
  z-index: $z-index-overlay;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-background-color;

  & > .redactor-close {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
}
