figure {
  margin: 0 auto $global-margin;
  text-align: center;

  &.figure-left {
    clear: both;
    float: left;
    margin: 0 $global-margin $global-margin 0;
  }

  &.figure-right {
    clear: both;
    float: right;
    margin: 0 0 $global-margin $global-margin;
  }
}

figcaption {
  color: $dark-gray;
  font-size: rem-calc(13);
  font-style: italic;
  text-align: center;
}

.rimg {
  float: right;
  margin-left: 10px;

  @include breakpoint(small only) {
    margin-bottom: 10px;
  }
}

.limg {
  float: left;
  margin-right: 10px;

  @include breakpoint(small only) {
    margin-bottom: 10px;
  }
}

.fotorama {
  @include grid-column-margin;
}
