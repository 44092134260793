.jstree {
  list-style: none;

  .hide {
    display: none;
  }

  ul {
    padding-left: 20px;
  }

  span {
    cursor: pointer;
    float: left;
    width: 12px;
  }

  label {
    font-size: 13px !important;
  }
}
