@use "sass:math";

// Strip Units
@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

// Clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Scale Font Size
@mixin font-scaled-size-up($scale) {
  font-size: 1em * math.pow($font-scale, $scale);
}

@mixin font-scaled-size-down($scale) {
  font-size: math.div(1em, math.pow($font-scale, $scale));
}
