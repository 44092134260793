//  Foundation for Sites Settings
//  -----------------------------
//

@import "~foundation-sites/scss/util/util";

$lite-blue: #2caef2;
$summer-sky: #39c;

// 1. Global
// ---------

$global-font-size: 14px;
$global-width: rem-calc(990);
$foundation-palette: (
  primary: #2373a5,
  secondary: #a35524,
  success: #52a324,
  warning: #a38a24,
  alert: #a32428,
);
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #0a0a0a;
$white: #fff;
$body-background: #dbf1ff;
$body-font-family: helvetica, arial, verdana, sans-serif;

//$body-antialiased: false;
$global-radius: 4px;
$global-menu-padding: rem-calc(9 6 9 7);
$global-flexbox: false;
$print-hrefs: false;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 990px,
);
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-column-gutter: (
  small: 10px,
  medium: 15px,
);

// 4. Base Typography
// ------------------

$header-styles: (
  small: (
    "h1": (
      "font-size": 24,
    ),
    "h2": (
      "font-size": 20,
    ),
    "h3": (
      "font-size": 18,
    ),
    "h4": (
      "font-size": 18,
    ),
    "h5": (
      "font-size": 17,
    ),
    "h6": (
      "font-size": 16,
    ),
  ),
  medium: (
    "h1": (
      "font-size": 24,
    ),
    "h2": (
      "font-size": 20,
    ),
    "h3": (
      "font-size": 18,
    ),
    "h4": (
      "font-size": 18,
    ),
    "h5": (
      "font-size": 17,
    ),
    "h6": (
      "font-size": 16,
    ),
  ),
);
$header-small-font-color: $dark-gray;
$anchor-color: $primary-color;
$anchor-color-hover: #0b4677;
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$blockquote-color: $black;
$blockquote-padding: 0.5rem 1rem;
$blockquote-border: 3px solid $summer-sky;

// 5. Typography Helpers
// ---------------------

$cite-color: $black;
$cite-font-size: rem-calc(16);

// 11. Button
// ----------

$button-padding: 0.5em 0.8em;
$button-background: #3185c6;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-sizes: (
  tiny: 0.8rem,
  small: 0.85rem,
  default: 0.9rem,
  more: 1rem,
  large: 1.1rem,
);

// 12. Button Group
// ----------------

$buttongroup-margin: 0;
$buttongroup-radius-on-each: false;

// 14. Card
// --------

$card-background: null;
$card-divider-background: transparent;
$card-border: none;
$card-padding: 0;

// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows: false;
$dropdownmenu-arrow-color: $white;
$dropdownmenu-arrow-size: 5px;
$dropdownmenu-background: null;
$dropdownmenu-padding: 0.5rem 0.4rem;
$dropdownmenu-border: none;
$dropdown-menu-item-color-active: $black;
$dropdown-menu-item-background-active: linear-gradient(to bottom, #fcfdff, #ccf0e7);
$dropdownmenu-submenu-background: #ccf0e7;

// 20. Forms
// ---------

$form-spacing: rem-calc(12);
$form-label-font-size: rem-calc(13);
$form-label-line-height: 1.7;
$input-font-size: rem-calc(13);
$input-border-focus: 1px solid $lite-blue;
$input-shadow: none;
$input-shadow-focus: 0 0 5px $primary-color;
$helptext-color: $dark-gray;

// 21. Media Object
// ----------------

$mediaobject-margin-bottom: 0;

// 25. Off-canvas
// --------------

$offcanvas-vertical-sizes: (
  small: 70px,
);
$offcanvas-background: $white;
$offcanvas-transition-length: 0.4s;
$offcanvas-exit-background: rgba($white, 0.7);
