@import "../style/settings";
@import "~foundation-sites/scss/foundation";

#ui-datepicker-div,
#ui-timepicker {
  z-index: 200 !important;
}

.redactor-modal {
  button {
    box-sizing: initial;
    height: initial;
    margin: initial;
    min-height: auto;
    overflow: initial;
    padding: 1rem;
    position: initial;
    width: initial;
  }

  &.open {
    left: initial !important;
    margin-left: auto !important;
  }

  &-header {
    padding: 20px 20px 0;
  }

  &-body {
    padding: 20px;
  }

  &-footer {
    padding: 20px;
  }
}

.redactor-box {
  background: #fff;

  .redactor-source {
    width: 100%;
  }
}

.redactor-styles {
  font-family: inherit;
}

.redactor-in {
  @include foundation-typography-base;
  @include foundation-typography-helpers;
  @include foundation-text-alignment;
  @include foundation-table;
  @include foundation-responsive-embed;
  @include foundation-float-classes;
  @include foundation-visibility-classes;
  @import "../style/layout/images";

  font-size: $global-font-size;

  h2,
  h3,
  h4,
  h5,
  h6 {
    background: initial;
    border: initial;
    border-radius: initial;
    text-shadow: initial;
  }

  blockquote {
    margin-left: 2rem;
  }
}

.grp-change-list {
  .column-analytics_display {
    .grp-text {
      width: 7rem;

      span {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .column-is_own,
  .column-is_main,
  .column-is_active {
    .grp-text {
      width: 5rem;

      a {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .grp-row {
    .field-owner_display {
      white-space: nowrap;
    }

    .field-is_own,
    .field-is_main,
    .field-is_active {
      text-align: center;
    }

    .field-analytics_display {
      text-align: right;
    }
  }
}

.field-image_thumbnail_display {
  img {
    object-fit: contain;
    height: 150px;
    width: 220px;
  }
}
